<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link to="createStaff" append class="btn btn-add btn_color_green"
          >Добавить техника</router-link
        >
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="staff"
      :table-data="staffList"
      :header="header"
      :source-object="sourceObject"
      v-if="sourceObject.results"
    />
  </div>
</template>

<script>
import TableFilters from "@/components/tables/TableFilters";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import tableColsStaff from "@/helpers/tableCols/staff";
export default {
  name: "ServesOrganizationStaff",
  components: { TableLayout, TableFilters },
  data() {
    return {
      loadingBtn: false,
      queries: query.staff
    };
  },
  computed: {
    header() {
      return tableColsStaff;
    },
    staffList() {
      return this.$store.getters.getServiceOrgStaffPageList.results.filter(
        el => el.position == 40
      );
    },
    sourceObject() {
      return this.$store.getters.getServiceOrgStaffPageList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadOrganizationStaffList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        groups__organization: this.urlQuery.id
      }
    });
    this.isDateLoaded = true;
    this.$store
      .dispatch("fetchServiceOrgStaffPageList", {
        organization_pk: this.urlQuery.id
      })
      .finally(() => {
        this.isDateLoaded = false;
      });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...this.urlQuery.query,
            groups__organization: this.urlQuery.id
          }
        });
        this.isDateLoaded = true;
        this.$store
          .dispatch("fetchServiceOrgStaffPageList", {
            organization_pk: this.urlQuery.id
          })
          .finally(() => {
            this.isDateLoaded = false;
          });
      }
    }
  }
};
</script>

<style scoped></style>
