import { render, staticRenderFns } from "./ServesOrganizationControllers.vue?vue&type=template&id=0457b2eb&scoped=true&"
import script from "./ServesOrganizationControllers.vue?vue&type=script&lang=js&"
export * from "./ServesOrganizationControllers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0457b2eb",
  null
  
)

export default component.exports